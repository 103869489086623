import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: #1877f2;
`;

const Disclosure = styled.div`
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 2rem;
`;

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const ProductCard = styled.div`
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 1rem;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const ProductImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: contain;
  border-radius: 4px;
  margin-bottom: 1rem;
  background-color: #f3f4f6;
  padding: 1rem;
`;

const ProductTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #1877f2;
`;

const ProductDescription = styled.p`
  color: #4b5563;
  margin-bottom: 1rem;
`;

const AmazonButton = styled.a`
  display: inline-block;
  background-color: #1877f2;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1664d9;
  }
`;

const FooterDisclosure = styled.div`
  font-size: 0.875rem;
  color: #6b7280;
  margin-top: 3rem;
`;

const Affiliate: React.FC = () => {
  return (
    <Container>
      <Title>Affiliate Products</Title>

      <Disclosure>
        <p>
          As an Amazon Associate I earn from qualifying purchases. This means that I may receive a small commission, at no cost to you, 
          if you make a purchase through any of the links below.
        </p>
        <p className="mt-2">
          Amazon and the Amazon logo are trademarks of Amazon.com, Inc. or its affiliates.
          All product recommendations are chosen independently by me. This site is a participant in the 
          Amazon Services LLC Associates Program, an affiliate advertising program designed to provide 
          a means for sites to earn advertising fees by advertising and linking to Amazon.com.
        </p>
      </Disclosure>

      {/* First Row of Products */}
      <ProductGrid>
        <ProductCard>
          <ProductImage src="https://m.media-amazon.com/images/I/51ou5HLzAGL._SX342_SY445_.jpg" alt="Axel Scheffler On the Farm" />
          <ProductTitle>Axel Scheffler On the Farm (Campbell Axel Scheffler)</ProductTitle>
          <ProductDescription>
            A delightful farm-themed board book perfect for young children, featuring charming illustrations by the renowned Axel Scheffler.
          </ProductDescription>
          <AmazonButton 
            href="https://www.amazon.com.au/dp/1509866949?showmri=&linkCode=ll1&tag=davidmadethis-22&linkId=3e6559e44a7b42cc49057861cf910edb&language=en_AU&ref_=as_li_ss_tl" 
            target="_blank" 
            rel="nofollow sponsored noopener"
          >
            Check Price on Amazon
          </AmazonButton>
        </ProductCard>

        <ProductCard>
          <ProductImage src="https://m.media-amazon.com/images/I/71UhlR8ow9L.__AC_SX300_SY300_QL70_FMwebp_.jpg" alt="Roizefar Tempera Paint Sticks Kit" />
          <ProductTitle>Roizefar Tempera Paint Sticks Kit</ProductTitle>
          <ProductDescription>
            12 Colors Paint Sticks, quick-drying and washable paints crayons for kids. Safe and versatile - suitable for paper, wood, and canvas.
          </ProductDescription>
          <AmazonButton 
            href="https://www.amazon.com/dp/B0DG8MBHJ6?&linkCode=ll1&tag=davidmadethis-20&linkId=b5c2bda995f6eb6dbcf08d57d5edfb41&language=en_US&ref_=as_li_ss_tl" 
            target="_blank" 
            rel="nofollow sponsored noopener"
          >
            Check Price on Amazon
          </AmazonButton>
        </ProductCard>

        <ProductCard>
          <ProductImage src="https://m.media-amazon.com/images/I/61d-Hf8dZaL.__AC_SX300_SY300_QL70_FMwebp_.jpg" alt="Strong Grip Pegs 50 Pieces Set" />
          <ProductTitle>Strong Grip Pegs 50 Pieces Set</ProductTitle>
          <ProductDescription>
            A versatile set of 50 strong grip pegs, perfect for various household and organizational needs.
          </ProductDescription>
          <AmazonButton 
            href="https://www.amazon.com/dp/B09KC659K9?&linkCode=ll1&tag=davidmadethis-20&linkId=27353ce1bc94f3fcd3e5a74604eba299&language=en_US&ref_=as_li_ss_tl" 
            target="_blank" 
            rel="nofollow sponsored noopener"
          >
            Check Price on Amazon
          </AmazonButton>
        </ProductCard>
      </ProductGrid>

      {/* Second Row of Products (New) */}
      <ProductGrid>
        <ProductCard>
          <ProductImage src="https://m.media-amazon.com/images/I/61Ry9-xUMCL.__AC_SX300_SY300_QL70_FMwebp_.jpg" alt="AmazonCommercial Wireless Noise Cancelling Bluetooth Headphones" />
          <ProductTitle>AmazonCommercial Wireless Noise Cancelling Bluetooth Headphones</ProductTitle>
          <ProductDescription>
            High-quality wireless Bluetooth headphones with active noise cancellation for immersive sound. Comfortable design for long listening sessions.
          </ProductDescription>
          <AmazonButton 
            href="https://amzn.to/410T0sh" 
            target="_blank" 
            rel="nofollow sponsored noopener"
          >
            Check Price on Amazon
          </AmazonButton>
        </ProductCard>

        <ProductCard>
          <ProductImage src="https://m.media-amazon.com/images/I/61HL9JJSBBL.__AC_SX300_SY300_QL70_FMwebp_.jpg" alt="AmazonCommercial Premium Double Sided Wired USB Headset" />
          <ProductTitle>AmazonCommercial Premium Double Sided Wired USB Headset</ProductTitle>
          <ProductDescription>
            Premium double-sided USB headset with noise-canceling microphone for crystal-clear calls. Ideal for office, remote work, and gaming.
          </ProductDescription>
          <AmazonButton 
            href="https://amzn.to/4hFBqzE" 
            target="_blank" 
            rel="nofollow sponsored noopener"
          >
            Check Price on Amazon
          </AmazonButton>
        </ProductCard>

        <ProductCard>
          <ProductImage src="https://m.media-amazon.com/images/I/61WM1xukklL.__AC_SX300_SY300_QL70_FMwebp_.jpg" alt="Stanley Quencher H2.0 Tumbler with Handle & Straw 30 oz" />
          <ProductTitle>Stanley Quencher H2.0 Tumbler with Handle & Straw 30 oz</ProductTitle>
          <ProductDescription>
            Insulated stainless steel tumbler with a twist-on 3-way lid. Cupholder compatible, BPA-free, and perfect for travel and daily hydration.
          </ProductDescription>
          <AmazonButton 
            href="https://amzn.to/4gtxzoc" 
            target="_blank" 
            rel="nofollow sponsored noopener"
          >
            Check Price on Amazon
          </AmazonButton>
        </ProductCard>
      </ProductGrid>

      <FooterDisclosure>
        <p>
          Product prices and availability are accurate as of the date/time indicated and are subject to change. 
          Any price and availability information displayed on Amazon at the time of purchase will apply to the 
          purchase of this product.
        </p>
        <p className="mt-4">
          Note: To maintain compliance with Amazon Associates Program Operating Agreement, all product links on this page 
          are tagged with my Associate ID. Reviews and recommendations are based on personal opinion and research. 
          Images are either my own or sourced from Amazon's product listings.
        </p>
      </FooterDisclosure>
    </Container>
  );
};

export default Affiliate;
