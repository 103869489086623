// src/pages/ProjectPage.tsx
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { projects } from '../projectsData';

// Import all images
import voltGiftsImage from '../images/volt-gifts.png';
import powerNetworkTycoonImage from '../images/power-network-tycoon.jpeg';
import cooperImage from '../images/cooper.jpg';
import theRitualImage from '../images/the-ritual.jpg';
import gameBreakImage from '../images/game-break.png';
import powerNetworkImage from '../images/power-network.jpg';
import dailyStockbotImage from '../images/daily-stockbot.jpg';
import explosionArenaImage from '../images/explosion-arena.jpg';
import hydrationStationImage from '../images/hydration-station.jpg';

const ProjectPageContainer = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
`;

const ProjectImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
`;

const ProjectTitle = styled.h1`
  color: #1877f2;
`;

const ProjectDescription = styled.p`
  font-size: 1.2rem;
  color: #1c1e21;
  margin-bottom: 20px;
`;

const Button = styled.a`
  display: inline-block;
  background-color: #1877f2;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px 0;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s;
  text-decoration: none;

  &:hover {
    background-color: #166fe5;
  }
`;

const PrivacyPolicyContent = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #f0f2f5;
  border-radius: 5px;
  text-align: left;
`;

const ProjectPage: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const project = projects.find(p => p.link === `/projects/${projectId}`);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  if (!project) {
    return <div>Project not found</div>;
  }

  const togglePrivacyPolicy = () => {
    setShowPrivacyPolicy(!showPrivacyPolicy);
  };

  // Map project titles to imported images
  const imageMap: { [key: string]: string } = {
    'Volt Gifts': voltGiftsImage,
    'Power Network Tycoon': powerNetworkTycoonImage,
    'Cooper': cooperImage,
    'The Ritual': theRitualImage,
    'Game Break (Discontinued)': gameBreakImage,
    'Power Network': powerNetworkImage,
    'Daily Stockbot (Discontinued)': dailyStockbotImage,
    'Explosion Arena (Discontinued)': explosionArenaImage,
    'Hydration Station (Discontinued)': hydrationStationImage,
  };

  return (
    <ProjectPageContainer>
      <ProjectTitle>{project.title}</ProjectTitle>
      <ProjectImage src={imageMap[project.title]} alt={project.title} />
      <ProjectDescription>{project.fullDescription}</ProjectDescription>
      <Button href={project.externalLink} target="_blank" rel="noopener noreferrer">
        External Link
      </Button>
      <br />
      <Button as="button" onClick={togglePrivacyPolicy}>
        {showPrivacyPolicy ? 'Hide Privacy Policy' : 'Show Privacy Policy'}
      </Button>
      {showPrivacyPolicy && (
        <PrivacyPolicyContent>
          <h2>Privacy Policy</h2>
          <div dangerouslySetInnerHTML={{ __html: project.privacyPolicy }} />
        </PrivacyPolicyContent>
      )}
    </ProjectPageContainer>
  );
};

export default ProjectPage;
