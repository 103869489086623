import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaDiscord, FaTwitter, FaYoutube, FaTiktok, FaTwitch, FaAmazon } from 'react-icons/fa';
import { SiBluesky } from 'react-icons/si';

const FooterContainer = styled.footer`
  background-color: #1877f2;
  color: #ffffff;
  padding: 2rem 1rem;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

const FooterLink = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  &:hover {
    text-decoration: underline;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const SocialIcon = styled.a`
  color: #ffffff;
  font-size: 1.5rem;
  transition: color 0.3s ease;
  &:hover {
    color: #e0e0e0;
  }
`;

const ButtonStyles = `
  display: inline-block;
  background-color: #ffffff;
  color: #1877f2;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 200px;
  text-align: center;
  &:hover {
    background-color: #e0e0e0;
  }
`;

const Button = styled.button`
  ${ButtonStyles}
`;

const ContactButton = styled(Link)`
  ${ButtonStyles}
`;

const ShareButton = styled(Button)``;

const Footer: React.FC = () => {
  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'David Made This - Innovative Power Engineering and Software Solutions',
        text: 'Check out David\'s portfolio for cutting-edge power engineering and software development!',
        url: window.location.href,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      console.log('Web Share API not supported');
      // Fallback to copying URL to clipboard
      navigator.clipboard.writeText(window.location.href)
        .then(() => alert('URL copied to clipboard!'))
        .catch((error) => console.error('Error copying URL:', error));
    }
  };

  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <FooterTitle>Quick Links</FooterTitle>
          <FooterLink to="/">Home</FooterLink>
          <FooterLink to="/portfolio">Portfolio</FooterLink>
          <FooterLink to="/contact">Contact</FooterLink>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Stay Connected</FooterTitle>
          <ContactButton to="/contact">Contact Me</ContactButton>
          <ShareButton onClick={handleShare}>Share This Page</ShareButton>
          <SocialLinks>
            <SocialIcon href="https://discord.gg/h3wxa5zY7X" target="_blank" rel="noopener noreferrer">
              <FaDiscord />
            </SocialIcon>
            <SocialIcon href="https://www.youtube.com/@davidmadethis_youtube" target="_blank" rel="noopener noreferrer">
              <FaYoutube />
            </SocialIcon>
            <SocialIcon href="https://twitter.com/DavidMadeThis" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </SocialIcon>
            <SocialIcon href="https://www.tiktok.com/@david_made_this" target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </SocialIcon>
            <SocialIcon href="https://www.twitch.tv/David_Made_This" target="_blank" rel="noopener noreferrer">
              <FaTwitch />
            </SocialIcon>
            <SocialIcon href="https://bsky.app/profile/davidmadethis.bsky.social" target="_blank" rel="noopener noreferrer">
              <SiBluesky />
            </SocialIcon>
            <SocialIcon as={Link} to="/affiliate" title="Amazon Affiliate Products" rel="nofollow sponsored">
              <FaAmazon />
            </SocialIcon>
          </SocialLinks>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Legal</FooterTitle>
          <p>&copy; 2024 David Made This. All rights reserved.</p>
          <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
        </FooterSection>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
